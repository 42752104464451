import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSettleList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refSettleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'type', label: 'Тип оп.', sortable: true },
    { key: 'date', label: 'Дата', sortable: false },
    { key: 'number', label: 'Номер', sortable: true },
    { key: 'product_name', label: 'Товар', sortable: true },
    { key: 'weight', label: 'Вес, тн', sortable: true },
    { key: 'paid_amount', label: 'Сумма $', sortable: true },
    { key: 'balance', label: 'Баланс $', sortable: true },
    { key: 'comments', label: 'Комменты', sortable: true }
  ]

  const queryFields = [
    { label: 'type', whereKeys: ['type'], sortKey: 'type' },
    { label: 'date', whereKeys: ['date'], sortKey: 'date' },
    { label: 'number', whereKeys: ['number'], sortKey: 'number' },
    { label: 'product_name', whereKeys: ['product_name'], sortKey: 'product_name' },
    { label: 'weight', whereKeys: ['weight'], sortKey: 'weight' },
    { label: 'paid_amount', whereKeys: ['paid_amount'], sortKey: 'paid_amount' },
    { label: 'balance', whereKeys: ['balance'], sortKey: 'balance' },
    { label: 'comments', whereKeys: ['comments'], sortKey: 'comments' },
    { label: 'client_id', whereKeys: ['client_id'], sortKey: 'client_id' }
  ]

  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('client_id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSettleListTable.value ? refSettleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })

  const refetchData = () => {
    refSettleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchTransaction = (ctx, callback) => {
    // console.log(ctx.filter.activeSettle)
    const whereCond = (statusFilter.value !== null ? `(status,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : '')
    // console.log(ctx.filter.kassSums.length)
    let fullWhere = whereCond
    if (router.currentRoute.params !== undefined && router.currentRoute.params.id !== undefined) {
      if (whereCond.length > 0) {
        fullWhere = `(${whereCond})~and(client_id,eq,${router.currentRoute.params.id})`
      } else {
        fullWhere = `(client_id,eq,${router.currentRoute.params.id})`
      }
    }

    store
      .dispatch('app-transaction/fetchSettleSub', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + queryFields.filter(item => item.label === sortBy.value)[0].sortKey,
        _where: fullWhere,
        _fields: '-',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'SettleSub_view'
      })
      .then(response => {
        const { records, total } = response.data

        callback(records)
        totalInvoices.value = total

        store.dispatch('app-transaction/fetchClient', { id: router.currentRoute.params.id }).then(cresp => {
          const [data] = cresp.data.records
          // console.log(data)
          store.commit('app-transaction/UPDATE_ACTIVE_SETTLE_CLIENT', data)
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Pending') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Success') return { variant: 'success', icon: 'CheckCircleIcon' }
    // if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Canceled') return { variant: 'danger', icon: 'InfoIcon' }
    // if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    // if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Canceled') return 'danger'
    // if (status === 'Closed') return 'danger'
    // if (status === 'Downloaded') return 'secondary'
    if (status === 'Pending') return 'warning'
    // if (status === 'Sent') return 'info'
    if (status === 'Success') return 'success'
    return 'primary'
  }

  const deleteSale = id => {
    store
      .dispatch('app-transaction/deleteSale', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.'
            }
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchTransaction,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSettleListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    deleteSale,
    t
  }
}
